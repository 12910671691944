export const ESTABLISHMENT_TYPES = [
  { value: "car_accessories", text: "Accesorios para autos" },
  { value: "coffee_shop", text: "Cafetería" },
  { value: "butchery", text: "Carniceria" },
  { value: "locksmith", text: "Cerrajería" },
  { value: "corporate_education", text: "Educación corporativa" },
  { value: "drug_store", text: "Farmacia" },
  { value: "hardware_store", text: "Ferretería" },
  { value: "flower_store", text: "Librería" },
  { value: "liquor_store", text: "Licorería" },
  { value: "car_wash", text: "Lavado de autos" },
  { value: "car_tires", text: "Llantas y rines" },
  { value: "furniture_store", text: "Mueblería" },
  { value: "lumber_store", text: "Maderería" },
  { value: "outdoor_furniture_store", text: "Muebles de jardín" },
  { value: "optician", text: "Óptica" },
  { value: "office_supply_store", text: "Papelería" },
  { value: "water_purifier", text: "Purificadora de agua" },
  { value: "car_parts", text: "Refaccionaria" },
  { value: "restaurant", text: "Restaurante" },
  { value: "school", text: "Escuela" },
  { value: "gym", text: "Gimnasio" },
  { value: "health_food_store", text: "Tienda de alimentos saludables" },
  { value: "clothing_store", text: "Tienda de ropa" },
  { value: "supplier_type", text: "Proveedor" },
  {
    value: "home_goods_store",
    text: "Tienda de artículos para el hogar",
  },
  { value: "paint_store", text: "Tienda de pinturas" },
  { value: "music_store", text: "Tienda de música" },
  { value: "pet_store", text: "Tienda de mascotas" },
  { value: "groceries", text: "Tiendita de la esquina" },
  { value: "tortilleria", text: "Tortillería" },
  { value: "jewelry_store", text: "Joyería" },
  { value: "car_sales", text: "Venta de autos" },
  { value: "other", text: "Otra" },
]
