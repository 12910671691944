<template>
  <main class="container">
    <b-form @submit.prevent="editEstablishmentPrompt()">
      <b-card>
        <div class="d-flex">
          <feather-icon icon="ShoppingBagIcon" size="19" />
          <h4 class="mb-0 ml-50">Información del establecimiento</h4>
        </div>
        <b-row>
          <b-col cols="12 my-1">
            <div class="d-flex">
              <base-cropper
                v-if="establishment"
                :model="establishment"
                @cropped-image="establishment.logo = $event"
              />
            </div>
          </b-col>
        </b-row>

        <div class="mt-1">
          <div>
            <b-form-group label="Nombre" label-for="name">
              <b-form-input
                v-model="establishment.name"
                class="form-control"
                placeholder=""
              />
            </b-form-group>
          </div>
          <div v-if="establishment.establishment_type === 'supplier'">
            <b-form-group
              label="Tipo de establecimiento"
              label-for="establishment_type"
            >
              <b-form-select
                v-model="establishment.establishment_type"
                :options="establishment_types"
              >
                <b-form-select-option
                  v-if="userData.role_name === 'admin'"
                  value="admin"
                >
                  Admin
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>

          <div class="mt-2">
            <b-checkbox id="is_supplier" v-model="establishment.is_supplier">
              ¿Deseas registrarte como provedor de productos ó servicios para
              otros negocios?
            </b-checkbox>
          </div>

          <div class="mt-1">
            <b-checkbox
              id="is_balance_shared"
              v-model="establishment.is_balance_shared"
            >
              ¿Manejarás una única cuenta bancaria para recibir o hacer pagos
              electrónicos como recargas de tiempo aire, recepción de pagos de
              servicios, pagos con tarjeta bancaria y transferencias?
            </b-checkbox>
          </div>
        </div>
      </b-card>

      <b-card>
        <div class="d-flex">
          <feather-icon icon="UserIcon" size="19" />
          <h4 class="mb-0 ml-50">Información de contacto</h4>
        </div>

        <div class="mt-2">
          <b-form-group label="Nombre" label-for="contact-name">
            <b-form-input
              v-model="establishment.contact_name"
              class="form-control"
            />
          </b-form-group>
        </div>

        <div>
          <b-form-group
            label="Número de Whatsapp para que tus clientes te contacten"
            label-for="mobile"
          >
            <b-form-input id="mobile" v-model="whatsappNumber" />
          </b-form-group>
        </div>

        <div cols="12" md="6" lg="4">
          <b-form-group label="Correo Electronico" label-for="website">
            <b-form-input id="website" v-model="establishment.contact_email" />
          </b-form-group>
        </div>
      </b-card>

      <b-card>
        <div class="d-flex">
          <feather-icon icon="ShoppingCartIcon" size="19" />
          <h4 class="mb-0 ml-50">Tienda en línea</h4>
        </div>

        <div class="landing__form mt-1" v-if="landingPage">
          <div>
            <b-form-group label="Titulo" label-for="title">
              <b-form-input id="title" v-model="landingPage.title" />
            </b-form-group>
          </div>

          <div>
            <b-form-group label="Descripción" label-for="description">
              <b-form-textarea
                id="description"
                v-model="landingPage.description"
                rows="4"
                placeholder="Escriba algo..."
              />
            </b-form-group>
          </div>

          <div>
            <b-form-group label="URL amigable" label-for="slug">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center mr-1">
                  https://compitienda.com/
                </div>
                <div class="mr-1">
                  <b-form-input id="slug" v-model="establishment.slug" />
                </div>
                <div>
                  <b-button
                    variant="primary"
                    @click="
                      copyPathToClipboard(`${urlOrigin}/${establishment.slug}`)
                    "
                  >
                    <feather-icon icon="CopyIcon" />
                    Copiar
                  </b-button>
                </div>
              </div>
            </b-form-group>
            <!-- URL currentPath + slug -->
          </div>

          <!-- <b-row>
            <b-col cols="12" md="8">
              <b-form-group label="Tema oscuro" label-for="dark_theme_enabled">
                <b-form-checkbox
                  id="dark_theme_enabled"
                  v-model="establishment.dark_theme_enabled"
                  switch
                />
                <small>
                  {{
                    establishment.dark_theme_enabled
                      ? "Activado"
                      : "Desactivado"
                  }}
                </small>
              </b-form-group>
            </b-col>
          </b-row> -->
        </div>

        <div>
          <b-form-group label="Imágenes de la tienda" label-for="images">
            <div class="base-cropper__container mb-1" v-if="landingPage">
              <div
                v-for="(image, index) in landingPage.images"
                :key="image.id || index"
                class="d-flex position-relative h-100"
              >
                <feather-icon
                  v-if="landingPage.images[index]"
                  @click="clearImage(index)"
                  icon="XCircleIcon"
                  size="26"
                  class="remove__icon"
                />
                <LandingBaseCropper
                  :image="image.url"
                  :index="index"
                  @update:image="updateImage(index, $event)"
                />
              </div>

              <div
                v-if="showAddImage"
                @click="landingPage.images.push({ url: '' })"
                class="border add__image__container"
              >
                <feather-icon icon="PlusIcon" size="26" />
              </div>
            </div>
          </b-form-group>
        </div>
      </b-card>

      <b-card>
        <div class="d-flex">
          <feather-icon icon="MapPinIcon" size="19" />
          <h4 class="mb-0 ml-50">Dirección</h4>
        </div>

        <div v-if="establishment.address_attributes">
          <p class="d-flex flex-column mt-1">
            <span class="text-muted"> Direccion actual </span>
            {{ establishment.address_attributes.street }}
            {{ establishment.address_attributes.ext_number }}, CP
            {{ establishment.address_attributes.postal_code }},
            {{ establishment.address_attributes.city }},
            {{ establishment.address_attributes.state }},
            {{ establishment.address_attributes.country }}
          </p>
        </div>
        <div class="mt-1">
          <google-map-form
            :latitude="latitude ? Number(latitude) : null"
            :longitude="longitude ? Number(longitude) : null"
            :zoom="latitude && longitude ? 10 : 4"
            @getAddress="setAddressForEdit($event)"
          />
        </div>
      </b-card>

      <b-card>
        <b-button variant="primary" class="mb-1" block type="submit">
          Guardar
        </b-button>

        <b-button
          variant="outline-secondary"
          block
          :to="{
            name: 'establishment-view',
            params: { id: $route.params.id },
          }"
        >
          Cancelar
        </b-button>
      </b-card>
    </b-form>
  </main>
</template>

<script>
import { mapActions } from "vuex"

// Components
import BaseCropper from "@/@core/components/BaseCropper.vue"
import AddressFormModel from "@/@core/components/AddressFormModel.vue"
import GoogleMapForm from "@/@core/components/google-map-form.vue"
import LandingBaseCropper from "@/@core/components/LandingBaseCropper.vue"

// Mixins
import messagesMixin from "@/@core/mixins/messagesMixin"
import { handleAPIErrors } from "@/utils/fetch-utils"
import { ESTABLISHMENT_TYPES } from "@/constants/establishment"

export default {
  components: {
    AddressFormModel,
    BaseCropper,
    LandingBaseCropper,
    GoogleMapForm,
  },
  mixins: [messagesMixin],
  data() {
    return {
      urlOrigin: process.env.VUE_APP_BASE_URL,
      userData: JSON.parse(localStorage.getItem("userData")),
      establishment_types: ESTABLISHMENT_TYPES,
      establishment: {
        address_attributes: {},
        logo: `${process.env.VUE_APP_API_URL}/default.jpg`,
      },
      newAddress: null,
      landingPage: null,
      imagesToDestroy: [],
      prevLandingPage: {},
      prevEstablishment: {},
      addressId: "",
      whatsappNumber: "",
      latitude: 0,
      longitude: 0,
    }
  },
  mounted() {
    this.fetchEstablishment(this.$route.params.id).then((response) => {
      this.establishment = response

      this.latitude = this.establishment.address_attributes?.lat
      this.longitude = this.establishment.address_attributes?.lng

      this.whatsappNumber = `${this.establishment.cel_number}`.slice(2)
      this.prevEstablishment = { ...this.establishment }

      this.addressId = this.establishment.address_attributes?.id

      this.landingPage = structuredClone(response.landing_page)
      this.prevLandingPage = structuredClone(this.landingPage)
    })
  },
  computed: {
    showAddImage() {
      if (!this.landingPage.images || this.landingPage.images.length === 0)
        return true
      const currentIndex = this.landingPage.images.length

      return (
        this.landingPage.images.length < 5 &&
        this.landingPage.images[currentIndex - 1]?.url
      )
    },
  },
  methods: {
    ...mapActions("establishments", [
      "fetchEstablishment",
      "editEstablishment",
      "editLandingPage",
    ]),
    ...mapActions("landingPage", ["editLandingPage"]),

    copyPathToClipboard(text) {
      navigator.clipboard.writeText(text)
      this.successToast(
        "Copiado",
        "La URL ha sido copiada al portapapeles",
        "CopyIcon"
      )
    },
    handleSharedBalanceButton(value) {
      this.establishment.is_balance_shared = value
    },
    handleIsSupplierButton(value) {
      this.establishment.is_supplier = value
    },
    setAddressForEdit(value) {
      this.newAddress = value
    },

    clearImage(index) {
      const imageId = this.landingPage.images[index]?.id
      if (imageId) {
        this.imagesToDestroy.push(imageId)
      }

      this.landingPage.images.splice(index, 1)
    },
    updateImage(index, event) {
      const imageId = this.landingPage.images[index]?.id
      if (imageId) {
        this.imagesToDestroy.push(imageId)
      }
      this.landingPage.images[index].url = event
      this.landingPage.images[index].id = ""
    },

    async editEstablishmentPrompt() {
      this.establishment.cel_number = this.whatsappNumber
        ? `${52}${this.whatsappNumber}`
        : ""

      const result = await this.$swal({
        title: "¿Estás seguro?",
        text: "Estás apunto de editar la información de el establecimiento",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, editar",
        cancelButtonText: "No, cancelar",
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        reverseButtons: true,
        buttonsStyling: false,
      })

      if (result.value) {
        this.$swal({
          title: "Guardando...",
          text: "Estamos guardando tus datos",
          didOpen: () => {
            this.$swal.showLoading()
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
        })

        try {
          await this.updateLandingPage()
          await this.updateEstablishment()
          this.successToast(
            "Se han editado tus datos",
            "Los datos han sido editados con éxito",
            "EditIcon"
          )
        } finally {
          this.$swal.close()
        }
      }
    },

    async updateLandingPage() {
      const regex = /^https:\/\/mywalleat\./

      let banners = []

      for (let i = 0; i < 5; i++) {
        const newImage = this.landingPage.images[i]?.url

        if (newImage && !regex.test(newImage)) {
          banners.push(newImage)
        }
      }

      const {
        images,
        image_1,
        image_2,
        image_3,
        image_4,
        image_5,
        ...landingPage
      } = this.landingPage

      landingPage.banners = banners
      landingPage.images_to_remove = this.imagesToDestroy

      if (!landingPage.banners.length && !landingPage.images_to_remove.length)
        return

      try {
        const res = await this.editLandingPage({
          establishment_id: this.$route.params.id,
          landing_page: landingPage,
        })
        this.landingPage = structuredClone(res)
        this.prevLandingPage = structuredClone(this.landingPage)
      } catch (e) {
        const errors = handleAPIErrors(e.response?.data)
        this.errorToast("Ocurrió un error", errors.join(", "))

        this.landingPage = { ...this.prevLandingPage }
      } finally {
        this.$swal.close()
      }
    },
    async updateEstablishment() {
      this.establishment.is_supplier = this.establishment.is_supplier
        ? "supplier"
        : "not_supplier"


      this.establishment.address_attributes = this.newAddress
        ? this.newAddress
        : this.establishment.address_attributes

      if (this.establishment.address_attributes) {
        this.establishment.address_attributes.id = this.addressId
      }

      try {
        await this.editEstablishment({
          id: this.$route.params.id,
          establishment: this.establishment,
        })

        this.establishment = { ...this.establishment }
        this.prevEstablishment = { ...this.establishment }
      } catch (e) {
        const errors = handleAPIErrors(e.response?.data)
        this.errorToast("Ocurrió un error", errors.join(", "))

        this.establishment = { ...this.prevEstablishment }
      } finally {
        this.$swal.close()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.submit__buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.add__image__container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 150px;
  width: 150px;
  position: relative;

  @media (max-width: 768px) {
    height: 150px;
    width: 150px;
  }
}

.base-cropper__container {
  display: flex;
  gap: 0.5rem;
  height: 150px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.remove__icon {
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 5%;
  color: red;
  z-index: 1;
}

.landing__form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
